<template>
  <div class="container">
     <p>DIARY</p>
  </div>
</template>



<script>
export default {
  name: 'ChessDiary',
  props:{
    user:Object,
    },
  
    data () {
     return {
         
     }
   }
}
</script>


<style >


</style>
